import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'gatsby-plugin-firebase';
import { useLocation } from '@reach/router';

export const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isInit, setIsInit] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [mode, setMode] = useState('');
  const [actionCode, setActionCode] = useState('');
  const location = useLocation();

  /**
   * Update some or all fields of the user Metadata.
   * @param {*} user
   */
  const updateUserMetadata = (metadataDocId, user) => {
    return firebase
      .firestore()
      .collection('userMetadata')
      .doc(metadataDocId)
      .update(user);
  };

  /**
   * Update user email.
   *
   * @param {*} email
   */
  const updateUserEmail = (email) => {
    return firebase.auth().currentUser.updateEmail(email);
  };

  /**
   * Update user password.
   *
   * @param {*} newPassword
   */
  const updateUserPassword = (newPassword) => {
    return firebase.auth().currentUser.updatePassword(newPassword);
  };

  /**
   *
   * @param {*} email
   * @param {*} password
   */
  const reauthenticateWithCredential = (email, password) => {
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    return firebase.auth().currentUser.reauthenticateWithCredential(credential);
  };

  /**
   * Refresh current user.
   */
  const refreshCurrentUser = async () => {
    await firebase.auth().currentUser.reload();
    const user = firebase.auth().currentUser;
    localStorage.removeItem('authUser');
    buildAndSetCurrentUser(user);
  };

  const isAuthenticated = () => (currentUser ? true : false);

  /**
   * Given a Firebase user, get its metadata and stamp the full object to state.
   *
   * @param {*} user
   */
  const buildAndSetCurrentUser = async (user) => {
    const savedUser = JSON.parse(localStorage.getItem('authUser'));
    if (!savedUser && !currentUser) {
      setShowConfirmation(true);
      setTimeout(() => {
        setShowConfirmation(false);
      }, 5000);
    }
    if (savedUser) {
      const {
        metadataDocId,
        firstName,
        lastName,
        uid,
        annotationsVisibility
      } = savedUser;
      setCurrentUser({
        ...user,
        firstName,
        lastName,
        uid,
        annotationsVisibility,
        metadataDocId
      });
      return;
    }
    const querySnapshot = await firebase
      .firestore()
      .collection('userMetadata')
      .where('uid', '==', user.uid)
      .get();

    return querySnapshot.forEach((doc) => {
      const authUser = {
        ...user,
        ...doc.data(),
        metadataDocId: doc.id
      };
      setCurrentUser(authUser);
      const {
        firstName,
        lastName,
        uid,
        annotationsVisibility,
        metadataDocId
      } = authUser;
      localStorage.setItem(
        'authUser',
        JSON.stringify({
          firstName,
          lastName,
          uid,
          annotationsVisibility,
          metadataDocId
        })
      );
    });
  };

  /**
   * Utility function for filtering out just the user metadata from a doc
   * snapshot.
   *
   * @param {*} user
   */
  const onlyUserMetadata = (user) => {
    const { firstName, lastName, uid, annotationsVisibility } = user;
    return { firstName, lastName, uid, annotationsVisibility };
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const actionCode = params.get('oobCode') || '';
    const mode = params.get('mode') || '';
    setActionCode(actionCode);
    setMode(mode);
  }, [location]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      return firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          await buildAndSetCurrentUser(user);
        } else {
          localStorage.removeItem('authUser');
          await setCurrentUser(null);
        }
        setIsInit(true);
      });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isInit,
        showConfirmation,
        mode,
        actionCode,
        updateUserMetadata,
        updateUserEmail,
        updateUserPassword,
        reauthenticateWithCredential,
        refreshCurrentUser,
        onlyUserMetadata,
        isAuthenticated,
        setMode,
        setActionCode
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthProvider;
